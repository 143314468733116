import {
  mrAuto,
  mlAuto,
  title,
  description,
  cardTitle,
  grayColor,
  section,
  container,
} from "assets/jss/material-kit-pro-react.jsx"

const aboutTeamStyle = {
  mrAuto,
  mlAuto,
  section,
  container,
  title: {
    ...title,
    fontWeight: "500",
    marginTop: "0",
  },
  description: {
    ...description,
  },
  cardTitle,
  cardDescription: {
    color: grayColor[0],
  },
  aboutTeam: {
    padding: "80px 0px",
  },
  textCenter: {
    textAlign: "center!important",
  },
  img: {
    width: "100%",
    height: "auto",
  },
  textMuted: {
    color: grayColor[7] + " !important",
  },
  justifyContent: {
    justifyContent: "center!important",
  },
}

export default aboutTeamStyle
