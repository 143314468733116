// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
import aboutTeamStyle from "assets/jss/material-kit-pro-react/views/aboutPageSections/aboutTeamStyle.jsx"
// core components
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import React from "react"

const useStyles = makeStyles(aboutTeamStyle)

export default function AboutTeam() {
  const classes = useStyles()
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer justifyContent="center">
          <GridItem md={10} sm={10} xs={12} className={classes.textCenter}>
            <h3 className={classes.title}>
              <i>Edgerton's Largest Annual Festival</i>
            </h3>
            <h5 className={classes.description}>
              It all began on a cold, wet autumn day in 1990 … dreamers,
              planners, cookers, musicians and skeptics, all together for the
              first of many fun-filled Chilimania festivals. As the years
              passed, we dialed in on a recipe for success: Chili, music,
              friends, fun, and (hopefully) some sunshine, and all for a good
              cause!
              <br />
              <br />
              Today, Chilimania is arguably one of the Midwest’s, if not the
              country’s, most entertaining and well-organized chili cook-off
              competitions. We offer a little something for all ages and
              interest. Stop by for a bite, some entertainment, or try your hand
              at one or more of the chili cooking contests.
            </h5>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  )
}
